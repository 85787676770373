<template>
  <el-dialog width="70%" :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Origem da conta:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        :loading="!firms"
        :remote-method="fetchFirms"
        value-key="uid"
        v-model="bill_.firm_id"
        size="medium"
      >
        <template #prefix>Empresa:</template>
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        remote
        filterable
        clearable
        v-model="bill_.partner_id"
        size="medium"
        value-key="uid"
        :remote-method="fetchPartners"
        :loading="!partners"
      >
        <el-option
          v-for="item in Partners"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
        <template #prefix>Fornecedor:</template>
      </el-select>

      <el-select
        remote
        filterable
        clearable
        v-model="bill_.cost_id"
        :remote-method="fetchCosts"
        @change="
          (v) => getCostEntity(v) | fetchEntities(true) | (entities = null)
        "
        size="medium"
        value-key="uid"
      >
        <template #prefix>Centro Custo:</template>
        <el-option
          v-for="item in Costs"
          :key="item.uid"
          :label="item.reference"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="entities?.length"
        value-key="uid"
        v-model="bill_.entity_id"
        size="medium"
      >
        <template #prefix>Entidade:</template>
        <el-option
          v-for="item in Entities"
          :key="item"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row :gutter="8">
        <el-col :md="12">
          <base-input
            type="number"
            label="NF:"
            hint="Nota Fiscal"
            mask="#*"
            v-model="bill_.invoice_id"
          >
          </base-input>
        </el-col>

        <el-col :md="12">
          <base-input
            label="Valor:"
            type="money"
            hint="R$"
            v-model="bill_.amount"
          >
          </base-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Observações:</h4>
      </el-row>
      <el-input
        type="textarea"
        :maxlength="254"
        :rows="4"
        v-model="bill_.obs"
      ></el-input>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="bill_.bought_at"
            type="date"
            size="medium"
            placeholder="Data Aquisição:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="bill_.expires_at"
            type="date"
            size="medium"
            placeholder="Data vencimento:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-collapse v-model="bill_.createInstallments" accordion>
        <el-collapse-item title="Parcelamento" name="1">
          <el-row :gutter="8" v-if="isNew">
            <el-col :md="6">
              <base-input
                label="Parcelas:"
                mask="###"
                hint="2x"
                v-model="bill_.quota"
              >
              </base-input>
            </el-col>
            <el-col :md="6">
              <base-input
                label="Valor:"
                type="money"
                v-model="bill_.quota_amount"
              >
              </base-input>
            </el-col>

            <el-col :md="12">
              <el-select v-model="bill_.payments_range" size="medium">
                <el-option
                  v-for="item in Periods"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <div v-if="(bill_.payments_range === 0 && bill_.quota) || !isNew">
            <el-row
              :gutter="8"
              type="flex"
              v-for="p in PaymentQtd"
              :key="p"
              justify="space-between"
            >
              <el-col :md="1">
                <div class="payment-order">#{{ p }}</div>
              </el-col>
              <el-col :md="11">
                <base-input
                  label="Valor:"
                  type="money"
                  hint="R$"
                  @blur="() => (!isNew ? updatePaymentByOrder(p) : null)"
                  v-model="payments_amount[p]"
                >
                </base-input>
              </el-col>

              <el-col :md="11">
                <el-date-picker
                  v-model="payments_expires_at[p]"
                  type="date"
                  size="medium"
                  placeholder="Vencimento:"
                  @blur="() => (!isNew ? updatePaymentByOrder(p) : null)"
                  :shortcuts="shortcuts"
                  format="DD/MM/YYYY"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification, ElDatePicker } from "element-plus";
import PartnerService from "../../services/partners";

export default {
  props: ["bill", "showModal"],
  components: { BaseInput, ElDatePicker },
  emits: ["close-modal", "should-update", "fetch-firms", "fetch-costs"],
  data() {
    return {
      bill_: { ...(this.bill || {}) },
      payments_amount: [],
      payments_expires_at: [],
      firms: null,
      partners: null,
      costs: null,
      entities: null,
      isLoadingSave: false,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchFirms(true);
    this.fetchPartners(true);
    this.fetchCosts(true);
  },
  watch: {
    payments_amount: {
      deep: true,
      handler: function () {
        this.updateTotalAmount();
      },
    },
    "bill_.quota": function () {
      this.calculatePaymentsAmount();
    },
    "bill_.quota_amount": function () {
      this.calculatePaymentsAmount();
    },
    bill(v) {
      this.bill_ = { ...(v || {}) };
      if (v?.firm) {
        this.bill_["firm_id"] = v?.firm?.uid;
        this.firms = [v.firm];
      }
      if (v?.partner) {
        this.bill_["partner_id"] = v?.partner?.uid;
        this.partners = [v.partner];
      }
      this.bill_["cost_id"] = v?.cost?.uid;
      //this.bill_["entity_id"] = v?.entity?.uid;

      if (v?.cost?.uid) this.getCostEntity(v.cost.uid);
      this.fetchEntities(true);

      this.setupInstallments();
    },
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Costs() {
      return this.costs || [];
    },
    PaymentQtd() {
      if (this.isNew)
        return Array.from(
          Array(Number(Number(this.bill_.quota)) || 0).keys()
        ).map((k) => k + 1);
      else return this.bill?.payments?.map((p) => p.order);
    },
    Firms() {
      return this.firms || [];
    },
    Entities() {
      return this.entities || [];
    },
    Periods() {
      return [
        {
          name: "inserir manualmente",
          value: 0,
        },
        {
          name: "mensal",
          value: 1,
        },
        {
          name: "bimestral",
          value: 2,
        },
        {
          name: "semestral",
          value: 6,
        },
        {
          name: "anual",
          value: 12,
        },
      ];
    },
    isNew() {
      return !(this.bill && "uid" in this.bill);
    },
    ModalTitle() {
      return !this.isNew ? "Editar conta" : "Cadastrar conta";
    },
    SafeAddr() {
      return this?.bill_?.addr || {};
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    calculatePaymentsAmount() {
      if (this.bill_.quota && this.bill_.quota_amount) {
        this.payments_amount = Array(Number(this.bill_.quota) + 1)?.fill(
          Number(this.bill_.quota_amount) || 0
        );
      }
    },
    updateTotalAmount() {
      if (this.paymentsWasUpdated())
        this.bill_.amount =
          this.payments_amount
            ?.slice(1)
            ?.reduce((t, p) => (t += Number(p) || 0), 0) || 0;
    },
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateBill() : this.createBill();
    },
    updatePaymentByOrder(order) {
      const amounts = this.payments_amount?.slice(1)?.reduce((t, a, i) => {
        t[i + 1] = Number(a);
        return t;
      }, {});

      const expires = this.payments_expires_at?.slice(1)?.reduce((t, a, i) => {
        t[i + 1] = new Date(a);
        return t;
      }, {});

      const p = this.bill_?.payments?.find((p) => p.order === order);

      this.updatePayment({
        uid: p?.uid,
        amount: amounts[order],
        expires_at: expires[order],
      });
    },
    createBill() {
      fetch(`${this.$store.state.apiUrl}bills`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          ...this.bill_,
          payments_amount: this.payments_amount.slice(
            1,
            Number(this.bill_.quota) + 1
          ),
          payments_expires_at: this.payments_expires_at.slice(
            1,
            Number(this.bill_.quota) + 1
          ),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update", { ...this.bill_, uid: e?.uid });
            this.shouldShow = false;
            this.bill_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar a conta",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    paymentsWasUpdated() {
      return (
        this.payments_amount
          ?.slice(1)
          ?.some((p, i) => p != this.bill?.payments[i]?.amount) ||
        this.payments_expires_at
          ?.slice(1)
          ?.some((p, i) => p != this.bill?.payments[i]?.expires_at)
      );
    },
    updatePayment(payment) {
      if (payment)
        return fetch(`${this.$store.state.apiUrl}payments/${payment.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(payment),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            () => {},
            () => {
              ElNotification.error({
                title: "Ocorreu um erro ao atualizar o pagamento",
                message: `Confira o pagamento de ordem ${payment.order}`,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
          });
      else return Promise();
    },
    updateBill() {
      fetch(`${this.$store.state.apiUrl}bills/${this.bill_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          ...this.bill_,
          payments_amount: this.payments_amount.slice(
            1,
            Number(this.bill_.quota) + 1
          ),
          payments_expires_at: this.payments_expires_at.slice(
            1,
            Number(this.bill_.quota) + 1
          ),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          async (e) => {
            this.$emit("should-update", this.bill_);
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a conta",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    getCostEntity(uid) {
      const entity = this.Costs.filter((e) => e.uid === uid);
      if (entity?.length) this.bill_.entity_name = entity[0].entity;
    },
    setupInstallments() {
      if (this.bill) {
        this.payments_amount = [
          0,
          ...(this.bill?.payments?.map((p) => Number(p.amount)) || []),
        ];
        this.payments_expires_at = [
          0,
          ...(this.bill?.payments?.map((p) => new Date(p.expires_at)) || []),
        ];
      }
    },
    fetchFirms(v) {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      url.search = new URLSearchParams({
        name: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    fetchCosts(v) {
      const url = new URL(`${this.$store.state.apiUrl}costs`);
      url.search = new URLSearchParams({ searchName: v === true ? "" : v });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.costs = json;
            this.$emit("fetch-costs", json);
          });
    },
    fetchEntities(v) {
      const url = new URL(`${this.$store.state.apiUrl}entities`);
      url.search = new URLSearchParams({
        entity_name: this?.bill_?.entity_name,
      });
      if (v && this?.bill_?.entity_name)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.entities = json));
    },
    async fetchPartners(v) {
      if (v) {
        const { partners } = await PartnerService().index({
          is_provider: true,
          searchName: v === true ? "" : v,
        });

        if (partners) this.partners = partners;
      }
    },
  },
  name: "BillModal",
};
</script>
<style scoped>
.mx-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.payment-order {
  font-weight: 600;
  height: 41px;
  line-height: 41px;
}
</style>